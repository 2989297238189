@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #F4F4F4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  html {
    font-family: NeueHaasDisplayMediu, sans-serif;
    scroll-behavior: smooth;
  }
}

@font-face {
  font-family: "NeueHaasDisplay";
  font-weight: 500;
  src: local("NeueHaasDisplay"),
    url("./assets/font/NeueHaasDisplay-Roman.ttf") format("truetype");
}

@font-face {
  font-family: "NeueHaasDisplayMediu";
  font-weight: 600;
  src: local("NeueHaasDisplay"),
    url("./assets/font/NeueHaasDisplay-Mediu.ttf") format("truetype");
}

@font-face {
  font-family: "NeueHaasDisplayBold";
  font-weight: 700;
  src: local("NeueHaasDisplay"),
    url("./assets/font/NeueHaasDisplay-Bold.ttf") format("truetype");
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

hr {
  border: 1px solid #efefef;
}

.css-1s2u09g-control {
  border-color: #efefef !important;
  border-radius: 0.375rem !important;
}

.css-1okebmr-indicatorSeparator {
  background-color: #efefef !important;
}
